<template>
  <div
    class="fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto duration-200 ease-in transform border-r text-gray-type-8 bg-gray-type-1 sm:static sm:block sm:translate-x-0 sm:transition-none transition-medium"
    :class="sidebarOpen ? 'translate-x-0' : '-translate-x-full'"
  >
    <div class="absolute top-0 left-0 pt-3 pl-4 sm:hidden">
      <button @click="sidebarOpen = !sidebarOpen" class="block text-white">
        <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path
            d="M17.293 18.707a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293z"
          ></path>
        </svg>
      </button>
    </div>
    <div class="flex flex-col h-screen pb-12 mt-4 sm:mt-0">
      <div class="flex items-center px-6 mt-12 space-x-3">
        <div
          class="flex items-center justify-center w-12 h-12 font-medium rounded-full bg-gray-type-2 text-gray-type-8"
          v-if="user && user.corporate_name"
        >
          {{ $filters.initials(user.corporate_name) }}
        </div>
        <div>
          <div class="text-xs font-bold text-gray-type-8" v-if="user && user.corporate_name">{{ user.corporate_name }}</div>
          <router-link
            to="/account-settings"
            class="mt-1 text-xs font-medium text-white"
          >
            Account settings
          </router-link>
        </div>
      </div>
      <nav class="flex-1 mt-10 sm:mt-20">
        <router-link
          to="/dashboard"
          class="flex items-center px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                  d="M14.25 19.5V15a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v4.5a.75.75 0 01-.75.75H4.5a.75.75 0 01-.75-.75v-8.668a.75.75 0 01.245-.555l7.5-6.82a.75.75 0 011.009 0l7.5 6.82a.75.75 0 01.246.555V19.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75z"
                  class="stroke-current"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Dashboard</div></router-link
        >
        <router-link
          to="/notifications"
          class="items-center justify-between hidden px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div class="flex items-center">
            <div>
              <svg
                class="w-4 h-4"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0h24v24H0z" />
                  <path
                    d="M5.269 9.75A6.74 6.74 0 0112.051 3c3.712.028 6.68 3.113 6.68 6.835v.665c0 3.358.703 5.306 1.322 6.371A.75.75 0 0119.408 18H4.592a.75.75 0 01-.645-1.13c.62-1.064 1.322-3.012 1.322-6.37v-.75zM9 18v.75a3 3 0 006 0V18"
                    class="stroke-current"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
            <div class="mt-1 ml-3">Notifications</div>
          </div>
          <div
            class="flex items-center justify-center w-6 h-6 text-xs font-medium text-white rounded-full bg-red-type-1"
          >
            2
          </div>
        </router-link>
        <router-link
          to="/users"
          class="flex items-center px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <circle
                  class="stroke-current"
                  stroke-width="1.5"
                  cx="12"
                  cy="9"
                  r="6"
                />
                <path
                  d="M2.905 20.25a10.504 10.504 0 0118.19 0"
                  class="stroke-current"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Users</div></router-link
        >
        <router-link
          to="/routes"
          class="flex items-center px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                  class="stroke-current"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.938 6.753h16.125M3.938 11.253h16.125M20.063 18H3.938V6a2.25 2.25 0 012.25-2.25h11.625A2.25 2.25 0 0120.063 6v12zM20.063 18v2.25a.75.75 0 01-.75.75h-2.25a.75.75 0 01-.75-.75V18M7.688 18v2.25a.75.75 0 01-.75.75h-2.25a.75.75 0 01-.75-.75V18"
                />
                <circle
                  class="fill-current stroke-current"
                  fill-rule="nonzero"
                  cx="7.875"
                  cy="14.625"
                  r="1.125"
                />
                <circle
                  class="fill-current stroke-current"
                  fill-rule="nonzero"
                  cx="16.125"
                  cy="14.625"
                  r="1.125"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Routes</div></router-link
        >
        <router-link
          to="/reports"
          class="flex items-center px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                  class="stroke-current"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12.003 12V3M19.794 7.5l-15.588 9M3.15 13.645A9.014 9.014 0 019 3.512v6.756l-5.85 3.377zM12 3a9 9 0 11-7.76 13.56"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Reports</div></router-link
        >
        <router-link
          to="/billing"
          class="items-center hidden px-4 py-4 text-xs font-medium hover:text-green-type-1 text-gray-type-8"
          active-class="text-green-type-1"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                  class="stroke-current"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 9h18l-9-6zM5.253 9v7.5M9.753 9v7.5M14.253 9v7.5M18.753 9v7.5M3 16.503h18M1.5 19.503h21"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Billing</div></router-link
        >
      </nav>
      <div class="px-4">
        <a
          href="#"
          @click.prevent="logout"
          class="flex items-center py-4 text-xs font-medium text-gray-type-8"
        >
          <div>
            <svg
              class="w-4 h-4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                  stroke="#AC3535"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.315 8.063L20.253 12l-3.938 3.938M9.75 12.003h10.5M11.25 20.25H4.5a.75.75 0 01-.75-.75v-15a.75.75 0 01.75-.75h6.75"
                />
              </g>
            </svg>
          </div>
          <div class="mt-1 ml-3">Logout</div></a
        >
        <div class="mt-12">
          <app-logo />
        </div>
      </div>
    </div>
  </div>
  <div class="absolute top-0 left-0 z-20 w-full p-2 bg-gray-type-10" :class="sidebarOpen ? 'hidden' : 'block'">
    <button
    @click="sidebarOpen = true"
    class="focus:outline-none focus:ring sm:hidden text-gray-type-1"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLogo from "@/components/AppLogo";

export default {
  components: {
    AppLogo,
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  watch: {
    '$route'() {
      if (this.sidebarOpen) {
        this.sidebarOpen = false;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$store.dispatch("resetStates");

        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>