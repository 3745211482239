<template>
  <div class="flex flex-col h-screen">
    <div class="flex flex-1 overflow-hidden">
      <app-sidebar></app-sidebar>
      <main class="flex flex-1 overflow-y-auto bg-gray-type-10">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import AppSidebar from "@/components/AppSidebar";

export default {
  components: {
    AppSidebar,
  },
};
</script>